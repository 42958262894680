/*md-backdrop.md-dialog-backdrop,
md-backdrop.md-select-backdrop
{
  z-index: 1000;
}
.md-dialog-container,
.md-select-menu-container
{
  z-index: 1001;
}*/
md-dialog {
  width: 50%;
}

html {
  overflow: hidden !important;
}

body {
  overflow: hidden;
  background-color: #eee;
}

main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
}

.pd8 {
  padding: 8px;
}

.pd16 {
  padding: 16px;
}

.c-red {
  color: #F44336 !important;
}

.c-green {
  color: #4CAF50 !important;
}

.whs-nowrap,
.speed {
  white-space: nowrap;
}

.ta-center {
  text-align: center !important;
}

.disabled {
  user-select: none !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: not-allowed !important;
}

.xm-readonly,
.xm-readonly-checkbox md-checkbox {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.md-row-total {
  font-weight: 700;
}

md-option {
  height: 34px;
}

md-input-container .md-errors-spacer,
md-input-container [ng-messages] {
  min-height: 0;
}
md-input-container input[type=time] {
  width: 78px;
}
md-input-container input[type=time].short-time {
  width: 70px;
}

.md-datepicker-input-container {
  width: 86px;
}

._md-datepicker-has-triangle-icon {
  margin-right: -12px;
}

.btns-filter .md-button.md-icon-button {
  padding: 8px 0;
  width: 30px;
}

.mobile .md-button.md-icon-button {
  padding: 8px 0;
  width: 22px;
}

.export-menu-item {
  margin: auto 3px auto 0;
}

.car-markers div {
  margin: 0 8px;
}
.car-markers img {
  margin: 0 8px;
}
.car-markers span {
  line-height: 20px;
  vertical-align: super;
}

#map {
  z-index: 1;
}

#content #table-data {
  background: #fff;
}
#content #map {
  background: #eee;
}

a.vehicle-link {
  text-decoration: none;
  font-weight: bold;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2),
table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child,
table.md-table td.md-cell:last-child,
table.md-table th.md-column:last-child {
  padding: 0 5px;
}

table.md-table thead.md-head > tr.md-row {
  height: 34px;
}

login section {
  background: url("/assets/img/bg/map-1.jpg");
}

driver-login section {
  background: url("/assets/img/bg/map-1.jpg");
}

md-select-menu {
  max-height: 512px;
}
md-select-menu md-content {
  max-height: 512px;
}

md-tabs-canvas,
md-pagination-wrapper {
  height: 36px;
}

md-pagination-wrapper {
  width: 100% !important;
}

.md-tab {
  padding-top: 6px;
  padding-bottom: 6px;
}

.md-button.md-icon-button {
  margin: 0;
}

vehicles {
  /*vehicles-details {
    md-tabs {
      height: 100%;
    }
  }*/
}
vehicles vehicles-all, vehicles vehicles-details {
  /*height: 100%;
  //@extend .flex;
  @include flex;

  md-content {
    height: 100%;
  }*/
}
vehicles vehicles-all .ta-center, vehicles vehicles-details .ta-center {
  text-align: center !important;
}
vehicles vehicles-all .car-address, vehicles vehicles-details .car-address {
  letter-spacing: 0.02em;
  font-size: 15px;
}
vehicles vehicles-now table.additional-info {
  width: 100%;
}
vehicles vehicles-now table.additional-info tr td:nth-child(odd) {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 24px;
}
vehicles .filter-bar .md-primary {
  min-width: auto;
}
vehicles .filter-bar md-input-container {
  margin-bottom: 0;
  height: 32px;
}

vehicles,
settings,
vehicles-all,
vehicles-details {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
}
vehicles md-tabs,
settings md-tabs,
vehicles-all md-tabs,
vehicles-details md-tabs {
  height: 100%;
}
vehicles md-content,
settings md-content,
vehicles-all md-content,
vehicles-details md-content {
  height: 100%;
  height: calc(100% - 30px);
}

vehicles-details md-tabs-content-wrapper {
  top: 37px !important;
  bottom: 40px;
}

vehicles-route .filter-bar md-input-container,
vehicles-stops .filter-bar md-input-container {
  margin-top: 0;
}

/*md-tabs {
  min-height: 100%!important;
}*/
md-fab-speed-dial {
  z-index: 500;
  position: absolute;
}

#table-data md-toolbar,
#table-data .md-toolbar-tools {
  min-height: 40px;
  height: 40px;
  color: #fff;
}
#table-data md-toolbar md-icon,
#table-data .md-toolbar-tools md-icon {
  color: #fff;
}
#table-data.flex-40 .table-car-all td.md-cell {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
#table-data.flex-40 .table-car-all .whs-nowrap {
  display: none;
}

.md-dialog-container md-toolbar,
.md-dialog-container .md-toolbar-tools {
  min-height: 50px;
  height: 50px;
}
.md-dialog-container .dialog-fullscreen,
.md-dialog-container .md-dialog-fullscreen {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

#osm-map-container {
  height: 100%;
}

.hide-map {
  max-width: 0 !important;
  width: 0 !important;
  max-height: 0 !important;
  height: 0 !important;
}

.hide-track #table-data {
  display: none;
}

.login-lang-menu {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-action {
  display: none;
}

.desktop .btn-toolbar.btn-action,
.mobile .btn-menu.btn-action {
  display: inherit;
}

.leaflet-control-attribution {
  display: none;
}

md-tab-content {
  -webkit-overflow-scrolling: touch;
}

.banners {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.banners a {
  display: block;
  width: 135px;
  height: 39px;
}
.banners .ios-link {
  background: url("/assets/img/app-store-png-logo-33109.png") no-repeat center/contain;
  margin-bottom: 5px;
}
.banners .android-link {
  background: url("/assets/img/favpng_google-play-android.png") no-repeat center/contain;
}

.md-button {
  margin: 2px 4px;
  min-height: 28px;
  line-height: 28px;
}

.md-button.md-icon-button {
  height: 28px;
  padding: 2px;
}

md-input-container {
  margin: 18px 0;
  margin-bottom: 5px;
  margin-top: 14px;
}

md-switch {
  margin: 0;
}

.md-select-value {
  min-height: 20px;
}

table.md-table td.md-cell {
  font-size: 14px;
}

.btns-filter.btns-filter__common-report .md-button.md-icon-button {
  width: 35px;
}

/*Loader*/
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(224, 242, 241, 0.8);
  border-right: 3px solid rgba(178, 223, 219, 0.8);
  border-bottom: 3px solid rgba(178, 223, 219, 0.8);
  border-left: 3px solid #E0F2F1;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*Loader end*/
.main-loader,
.widget-loader {
  top: 50%;
  left: 50%;
  margin: -24px 0 0 -24px;
  z-index: 2;
}

.main-loader {
  position: fixed;
}

.widget-loader {
  position: absolute;
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  z-index: 2;
}

.loader-md-top {
  position: absolute;
  top: 68px;
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 32px;
  z-index: 2;
}

.loader-list {
  position: absolute;
  top: 28px;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
  width: 32px;
  height: 32px;
  z-index: 2;
}

/*Lazy load*/
.list-lazy-load {
  position: sticky;
  height: 56px;
  bottom: 0;
  left: 0;
  right: 0;
}
.list-lazy-load .loader {
  width: 32px;
  height: 32px;
}

.list-lazy-load-text {
  margin-left: 16px;
  color: #9E9E9E;
}

/*Lazy load end*/
.cars-list {
  position: relative;
}
.cars-list md-input-container {
  margin: -4px 8px 0;
}
.cars-list md-option {
  height: 34px;
}
.cars-list .md-icon-button {
  min-height: 30px;
  height: 30px;
  padding: 4px 8px;
}

.stopped, .moving {
  background-color: #E0E0E0;
}

.no-gps {
  background-color: #E0E0E0;
}

.no-online-gps {
  background-color: #FFFDE7;
}

.online-gps {
  background-color: #E0FFE0;
}

.moving .speed {
  color: #4CAF50 !important;
  font-weight: bold;
}

.speed {
  text-align: left !important;
}
.speed .material-icons {
  font-size: 16px !important;
  vertical-align: middle !important;
}

.road_sign {
  background-color: #214a87;
  color: white;
}

.black {
  color: black !important;
}

.current-stop {
  background-color: #C8E6C9;
}

.route-color-box {
  display: block;
  width: 15px;
  height: 15px;
  margin: 5px;
  box-sizing: border-box;
  border: 0.5px solid black;
}

.table-car-all.hide-col-0 .md-head th:nth-child(1),
.table-car-all.hide-col-0 .md-body tr:not(.car-cell-address) td:nth-child(1) {
  display: none;
}
.table-car-all.hide-col-1 .md-head th:nth-child(2),
.table-car-all.hide-col-1 .md-body td:nth-child(2) {
  display: none;
}
.table-car-all .md-row {
  height: 20px !important;
}
.table-car-all .car-cell-address {
  height: 20px !important;
}
.table-car-all .car-cell-address .md-cell {
  border-top-color: rgba(0, 0, 0, 0.05);
  text-align: center !important;
}

.car-settings {
  padding: 16px;
}
.car-settings md-divider {
  margin: 16px 0;
}

.dialog-report {
  width: 90%;
  max-width: 96%;
}

.car-link,
.report-table .md-cell.car-link,
.balance-table .md-cell.car-link {
  color: rgb(0, 0, 238);
  text-decoration: underline;
  cursor: pointer;
}

.report-table .md-cell.car-link:first-child,
.balance-table .md-cell.car-link:first-child {
  background: #a8c0f0;
  white-space: nowrap;
}

.report-table tbody tr td:last-child {
  font-weight: 700;
}
.report-table tbody tr:last-child td {
  font-weight: 700;
}
.report-table tbody tr:last-child td.car-link {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}
.report-table .c-red {
  color: inherit !important;
  background-color: #f9d7cb;
}

.report-table tbody tr:hover td,
.balance-table tbody tr:hover td {
  background-color: #E0E0E0 !important;
}
.report-table tbody tr:nth-child(even),
.balance-table tbody tr:nth-child(even) {
  background-color: #EEEEEE;
}

@media (max-width: 1200px) {
  .dialog-balance {
    width: inherit;
  }
}

.change-address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  padding-left: 12px;
  background-color: #c0c0c0;
}

.change-address__address-title {
  margin-left: 60px;
}

.filter-bar-time .car-link {
  margin: 2px 8px 0;
}

.mobile .filter-bar-time .car-link {
  margin: 2px 4px 0;
}

.car-all-sort-blk {
  position: absolute;
}
.car-all-sort-blk .material-icons {
  line-height: 16px;
  font-size: 20px;
}

.copy-btn {
  float: right;
}

.car-filter {
  margin-left: 15px;
  font-size: 15px;
}

.settings .md-subheader {
  font-size: 18px;
  text-align: center;
}
.settings .md-subheader .md-subheader-inner {
  padding: 0 0 16px 0;
}
.settings .settings-row-caption {
  margin-right: 16px;
}

.settings-all md-divider {
  margin: 16px 0;
}

.md-secondary-container .md-secondary {
  opacity: 0.5;
}
.md-secondary-container .md-secondary:hover {
  opacity: 1;
}

.zone-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 16px;
}

.dialog-user md-checkbox {
  display: block;
}

.settings-subscription-fee {
  height: 100%;
}
.settings-subscription-fee .pd16._md {
  height: calc(100% - 32px);
}
.settings-subscription-fee .car-without-plan {
  color: #F44336;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}

.settings-users .user-permissions span {
  font-size: 12px;
  margin-right: 12px;
}
@media screen and (max-width: 425px) {
  .settings-users .user-permissions {
    display: none;
  }
}
.settings-users md-switch {
  margin: 0;
}
.settings-users md-checkbox {
  margin-bottom: 6px;
  margin-top: 5px;
}
.settings-users .layout-row {
  width: 75%;
  padding: 0 10px;
  flex-wrap: wrap;
}

md-switch[disabled].activeDisabled .md-container > div > div {
  background: rgb(255, 171, 64);
}

fuel-chart {
  display: none;
  position: relative;
}

#fuel-chart {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgb(189, 189, 189);
}

.fuel-chart-container {
  display: block;
  min-height: 40%;
}
.fuel-chart-container .export-btn {
  position: absolute;
  right: 8px;
  bottom: -4px;
}

.fuel-summary-chart {
  z-index: 1001;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  font-size: 12px;
  padding: 3px;
}

.fuel-chart-msg {
  position: absolute;
  top: 48%;
  margin-left: 25px;
}

.logistics-upload-btn {
  position: relative;
  overflow: hidden;
}
.logistics-upload-btn > input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.logistics {
  height: 100%;
}
.logistics .md-subheader {
  font-size: 18px;
  text-align: center;
}
.logistics .md-subheader .md-subheader-inner {
  padding: 0 0 16px 0;
}
.logistics .create-route .had-no-coordinates {
  background-color: #FFF9C4;
}
.logistics .create-route .no-coordinates {
  background-color: #FFCDD2;
}
.logistics .create-route .selected {
  background-color: #E0FFE0;
}
.logistics .create-route .start-point-btn {
  color: white;
  background-color: #29B6F6;
}
.logistics .create-route .end-point-btn {
  color: white;
  background-color: #FF7043;
}
.logistics .create-route .status-container {
  display: flex;
  flex-direction: row;
}
.logistics .create-route .not-clickable-icon {
  margin: 8px;
  opacity: 0.5;
}
.logistics .create-route .instruction {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.logistics .create-route .instruction.select-car-wrapper .select-car-title {
  margin-right: 15px;
}
.logistics .create-route .instruction.select-car-wrapper .input-container {
  margin: 0;
}
.logistics .create-route .routes-tabs {
  width: 100%;
}
.logistics .create-route .color-point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.logistics .create-route .point-car-wrapper {
  display: flex;
  flex-direction: row;
}
.logistics .create-route .point-car-wrapper .point-car-select {
  margin: 5px;
}
.logistics .create-route .over-weight {
  color: red;
}
.logistics .create-route .point-moving a {
  text-decoration: none;
}
.logistics .create-route .point-moving a + a {
  margin-left: 10px;
}
.logistics .create-route .point-parking, .logistics .create-route .point-desired-position {
  width: 50px;
}
.logistics .routes .filter-bar .period-link {
  margin: 2px 8px 0;
  color: rgb(0, 0, 238);
  cursor: pointer;
}
.logistics .routes .filter-bar .period-link.selected {
  text-decoration: underline;
  cursor: default;
}
.logistics .routes .routes-table .route-row.accepted {
  background-color: #E0FFE0;
}
.logistics .view-route .accepted {
  color: #43A047;
}
.logistics .view-route .non-accepted {
  color: #039BE5;
}
.logistics .view-route .outdrive {
  color: #43A047;
}
.logistics .view-route .delay {
  color: #f44336;
}
.logistics .view-route .not-visited {
  background-color: #FFCDD2;
}
.logistics .back-link {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 15px;
  left: 20px;
}
.logistics .location-autocomplete {
  margin-right: 15px;
}
.logistics .small-btn {
  min-width: 40px;
}
.logistics .custom-point-focus, .logistics .selection-focus, .logistics .watching-map-click-focus {
  background-color: #EEEEEE !important;
}
.logistics .xs-container {
  flex: 0.5;
}

.leaflet-routing-container {
  display: none;
}

.colored-icon {
  width: 30px;
  height: 30px;
  display: block;
  left: -15px;
  top: -15px;
  position: relative;
  border-radius: 30px 30px 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;
}
.colored-icon .colored-icon-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-weight: bold;
}

.sorting-tittle {
  cursor: pointer;
}
.sorting-tittle:hover {
  text-decoration: underline;
}
.sorting-arrow {
  transition: transform 0.3s ease-out;
}
.sorting-arrow-up {
  transform: rotate(180deg);
}

.sorting-title {
  position: relative;
}
.sorting-title .label {
  margin-right: 20px;
}
.sorting-title .icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}
.sorting-title .icon-wrapper .material-icons {
  font-size: 17px;
  line-height: 15px;
}

.date-column {
  width: 10em;
}

.column-center {
  text-align: center !important;
}

.geozones-index {
  padding-right: 15px;
}

.leaflet-control-layers-toggle {
  background-image: url(/assets/img/layers.png);
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/assets/img/layers-2x.png);
}

.leaflet-popup-content {
  margin: 4px 8px;
}

.leaflet-draw-toolbar a,
.leaflet-retina .leaflet-draw-toolbar a {
  background-image: url(/assets/img/spritesheet.png) !important;
}

.leaflet-control-coordinates {
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  cursor: pointer;
  padding: 2px 5px;
}

.leaflet-control-coordinates.hidden {
  display: none;
}

.leaflet-control-coordinates-lng {
  padding-left: 5px;
}

.leaflet-control-draw-measure {
  background-image: url(/assets/img/measure-control.png);
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.c3 path, .c3 line {
  fill: none;
  stroke: #000;
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3;
}

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 1px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.4 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px;
}

.c3-legend-item-hidden {
  opacity: 0.45;
}

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9;
}

.c3-tooltip tr {
  border: 1px solid #CCC;
}

.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF;
}

.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

@media (max-width: 960px) {
  vehicles-all .whs-nowrap {
    white-space: inherit;
  }
  #table-data .md-toolbar-tools {
    padding: 0 8px;
  }
  #table-data .md-toolbar-tools > .md-button:first-child .material-icons {
    display: none;
  }
  #table-data .md-toolbar-tools .md-icon-button {
    width: auto;
    padding: 4px;
  }
  #table-data .md-toolbar-tools .md-title {
    font-size: 16px;
  }
  .additional-info {
    padding-bottom: 20px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 479px) and (orientation: portrait) {
  #content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  #content #map {
    max-width: 100%;
  }
  #content #map.flex-50:not(.flex-100) {
    max-height: 240px;
  }
  #content #table-data {
    max-width: 100%;
    min-height: initial;
  }
  .hide-portrait {
    display: none;
  }
}
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  #content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  #content #map {
    max-width: 100%;
  }
  #content #table-data {
    max-width: 100%;
    min-height: initial;
  }
  .hide-portrait {
    display: none;
  }
}
.full-width {
  width: 100% !important;
}

.with-search .material-icons {
  position: absolute;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

color-picker-h {
  display: inline-block;
  width: 100px;
}
color-picker-h .color-item {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 5px;
  box-sizing: border-box;
}
color-picker-h .color-item.selected {
  border: 2px solid #424242;
}

.car-info-container {
  display: block;
  height: 70%;
  overflow: auto;
}
.car-info-container .md-cell {
  font-size: 12px !important;
}

[is-visible-map-control=true] {
  display: block;
}
[is-visible-map-control=true][is-permanently-hidden-map-control] {
  display: none;
}

[is-visible-map-control=false] {
  display: none;
}

.vehicles-settings {
  gap: 20px;
}

.vehicles-settings md-select {
  margin-bottom: 0;
}

.vehicles-settings__icon-preview {
  margin-right: auto;
}

.vehicles-settings__icon {
  width: 30px;
  height: 30px;
}

.vehicles-settings-menu__content {
  min-width: auto;
}

.vehicles-settings-menu__item {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
}

.vehicles-settings-menu__selected-item .md-button,
.vehicles-settings-menu__selected-item .md-button:hover {
  background-color: transparent;
  cursor: default;
}
.vehicles-settings-menu__selected-item .vehicles-settings-menu__item-icon {
  display: inline-block;
}

.vehicles-settings-menu__item--color {
  display: block;
  width: 30px;
  height: 30px;
}

.vehicles-settings-menu__item-icon {
  display: none;
  width: 12px;
}

.vehicles-settings-menu__item-icon-img {
  height: 30px;
}

.leaflet-div-icon.vehicle-icon {
  background: transparent;
  border: none;
}

geo-zones-with-stops-table .geo-zone-name {
  box-sizing: border-box;
  padding: 5px;
  background-color: rgba(192, 192, 192, 0.3);
}
geo-zones-with-stops-table .total-row {
  font-weight: bold;
}

.export-btn__separator {
  display: block;
  padding: 10px 16px;
  box-sizing: border-box;
}
.export-btn__separator::before {
  content: "";
  display: block;
  border-top: 1px solid #dddddd;
}

md-menu-item[md-menu-item-with-separator=true] {
  min-height: auto;
  height: auto;
}

.fuel-report-main-table {
  margin: 0 auto;
}

md-checkbox.md-checkbox--gray .md-icon {
  background-color: rgba(0, 0, 0, 0.38);
}

.sub-toolbar {
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 5px;
  background-color: rgba(192, 192, 192, 0.5);
}
.sub-toolbar md-button,
.sub-toolbar .md-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-width: 30px;
}
.sub-toolbar .change-address__address-title {
  margin-left: 0;
}
.sub-toolbar md-radio-group,
.sub-toolbar .md-radio-group {
  background: transparent;
}
.sub-toolbar md-radio-button {
  margin-bottom: 0;
}
.sub-toolbar > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.sub-toolbar > *:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
}

.fuel-report-view {
  position: relative;
}
.fuel-report-view .fuel-report-view__preloader {
  top: 100px;
}
.fuel-report-view .accordion v-pane {
  border: solid 1px #cccccc;
}
.fuel-report-view .accordion v-pane-header {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  box-sizing: border-box;
  padding: 10px 10px;
}
.fuel-report-view .accordion v-pane-header:hover {
  background-color: #eeeeee;
}
.fuel-report-view .accordion v-pane-header > div {
  display: block;
  width: 100%;
  font-weight: bold;
  margin-left: auto;
}
.fuel-report-view .accordion v-pane-header::before {
  content: "+";
  display: block;
  width: 16px;
  text-align: center;
  color: #2196f3;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}
.fuel-report-view .is-expanded v-pane-header {
  border-bottom: 2px solid #2196f3;
  background-color: #eeeeee;
}
.fuel-report-view .is-expanded v-pane-header::before {
  content: "-";
}
.fuel-report-view .fuel-report-view-accordion-header {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.fuel-report-view .fuel-report-view-accordion-header md-checkbox {
  margin-bottom: 0;
}
.fuel-report-view .fuel-report-view-accordion-header .fuel-report-view-accordion-header__checkbox {
  margin-left: auto;
  flex-grow: 0;
}
.fuel-report-view .fuel-report-view-accordion-content {
  display: block;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table {
  table-layout: fixed;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr th {
  text-align: center;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr th,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr td {
  box-sizing: border-box;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-start-time-column,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-end-time-column,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-parking-duration-column,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-movement-duration-column {
  width: 70px;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-start-time-value,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-end-time-value {
  text-align: center;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-parking-duration-value,
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-movement-duration-value {
  text-align: center;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-route-color-column {
  width: 35px;
  text-align: center;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-info-column {
  width: auto;
}
.fuel-report-view .fuel-report-view-accordion-content .fuel-report-view-accordion-content__table tr .is-checkbox-column {
  width: 45px;
}
.fuel-report-view [fuel-report-view-event-row] .is-link {
  color: rgb(0, 0, 238);
  text-decoration: underline;
  cursor: pointer;
}
.fuel-report-view .route-summary-info,
.fuel-report-view route-summary-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.fuel-report-view .route-summary-info > * > *:first-child,
.fuel-report-view route-summary-details > * > *:first-child {
  display: inline-block;
}
.fuel-report-view .route-summary-info > * > *:first-child:first-letter,
.fuel-report-view route-summary-details > * > *:first-child:first-letter {
  text-transform: capitalize;
}
.fuel-report-view .route-summary-info > * > *:first-child::after,
.fuel-report-view route-summary-details > * > *:first-child::after {
  content: ":";
  box-sizing: border-box;
}
.fuel-report-view .route-summary-info > *:not(:last-child) > *:last-child::after,
.fuel-report-view route-summary-details > *:not(:last-child) > *:last-child::after {
  display: inline-block;
  content: ",";
}

md-checkbox.usage-of-all-controversial-events-toggle {
  margin-bottom: 0;
}

.route-summary-info,
route-summary-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.route-summary-info > * > *:first-child,
route-summary-details > * > *:first-child {
  display: inline-block;
}
.route-summary-info > * > *:first-child:first-letter,
route-summary-details > * > *:first-child:first-letter {
  text-transform: capitalize;
}
.route-summary-info > * > *:first-child::after,
route-summary-details > * > *:first-child::after {
  content: ":";
  box-sizing: border-box;
}
.route-summary-info > *:not(:last-child) > *:last-child::after,
route-summary-details > *:not(:last-child) > *:last-child::after {
  display: inline-block;
  content: ",";
}

.common-report-dialog-placeholder {
  display: none;
}

@media (min-width: 480px) {
  .with-common-report-dialog #osm-map-container {
    height: 50%;
  }
  .with-common-report-dialog md-dialog {
    overflow: hidden;
  }
  .with-common-report-dialog common-report-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  .with-common-report-dialog md-content {
    overflow: auto;
  }
  .with-common-report-dialog md-backdrop:not(.md-click-catcher) {
    visibility: hidden;
  }
  .with-common-report-dialog .md-dialog-container {
    max-width: 50%;
    max-height: 50%;
    top: auto !important;
    bottom: 0;
  }
  .with-common-report-dialog .md-scroll-mask {
    display: none;
  }
  .with-common-report-dialog .md-select-menu-container.md-active {
    left: 0 !important;
    min-width: 50% !important;
  }
  .with-extended-width-of-map.with-common-report-dialog .md-dialog-container {
    max-width: 60%;
  }
}
settings-tokens {
  display: block;
}

token-name {
  display: block;
  width: 100%;
}
token-name md-input-container {
  position: relative;
  transform: translateY(1px);
  width: 100%;
  margin: 0;
  padding: 0;
}
token-name md-input-container .md-input {
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
}
token-name md-input-container.md-input-focused .md-input {
  border-bottom-width: 1px;
}

.token-name__edit-ui-mode {
  display: flex;
}
.token-name__edit-ui-mode .md-button {
  width: 35px;
  min-width: auto;
}
.token-name__edit-ui-mode .md-button .md-ripple-container {
  width: 100%;
}

.settings-tokens__list {
  display: flex;
  flex-direction: column;
}

.settings-tokens__list-item {
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #cecece;
}

.settings-tokens__list-item-row {
  display: flex;
  gap: 40px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.settings-tokens__list-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.01);
}

.settings-tokens__list-item-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.settings-tokens__header-left-section {
  width: 100%;
  overflow: hidden;
}

.settings-tokens__header-right-section {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.settings-tokens__token-name {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settings-tokens__token-name--empty {
  color: rgba(0, 0, 0, 0.3);
  font-weight: normal;
  font-style: italic;
}

.settings-tokens__token-id {
  display: none;
  font-size: 12px;
  color: #aaaaaa;
}

.settings-tokens__is-enabled-switch md-switch {
  flex-direction: row-reverse;
  gap: 10px;
  font-size: 14px;
}
.settings-tokens__is-enabled-switch md-switch .md-container {
  margin-right: 0;
}

.settings-tokens__copy-btn {
  width: 35px;
}
.settings-tokens__copy-btn .md-button {
  width: 100%;
  min-width: auto;
}
.settings-tokens__copy-btn .md-button .md-ripple-container {
  width: 100%;
}

.settings-tokens__token-expiration-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.settings-tokens__token-expiration-date-label {
  font-size: 14px;
}

.settings-tokens__list-item-row--first-row,
.settings-tokens__list-item-row--2nd-row {
  align-items: center;
}

.settings-tokens__list-item-row--vehicles {
  align-items: center;
}
.settings-tokens__list-item-row--vehicles .settings-tokens__header-left-section {
  width: 100%;
}
.settings-tokens__list-item-row--vehicles md-select {
  margin: 0;
}

.settings-tokens__list-item-row--other {
  justify-content: space-between;
  align-items: start;
}
.settings-tokens__list-item-row--other md-checkbox {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin: 0;
  gap: 5px;
  min-width: auto;
}
.settings-tokens__list-item-row--other md-checkbox .md-label {
  font-size: 14px;
  margin: 0;
}
.settings-tokens__list-item-row--other md-checkbox .md-container {
  position: relative;
  top: auto;
  transform: none;
}

.settings-tokens__list-head {
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.settings-tokens__list-head > *:nth-child(1) {
  width: 50px;
}

.settings-tokens__list-row {
  display: flex;
  justify-content: space-between;
}

.settings-tokens__loading-screen {
  display: flex;
  width: 100%;
  height: 100%;
  background: red;
}

.with-loading-tokens [ui-view=tokens] {
  height: 100%;
}
.with-loading-tokens settings-tokens {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.settings-token__with-unlimited-validity-checkbox {
  align-self: start;
  margin-top: 5px;
}
.settings-token__with-unlimited-validity-checkbox md-checkbox {
  flex-direction: row-reverse;
  gap: 10px;
}

.settings-token__with-unlimited-validity .settings-tokens__token-expiration-date-label,
.settings-token__with-unlimited-validity md-datepicker {
  pointer-events: none;
  opacity: 0.3;
}

.with-access-by-token #table-data .table-car-all td, .with-access-by-token #table-data .table-car-all th {
  box-sizing: border-box;
}
.with-access-by-token table.md-table td.md-cell.md-numeric,
.with-access-by-token table.md-table th.md-column.md-numeric {
  text-align: left;
}

.with-loading-route-with-token [ui-view=viewRoute] {
  height: 100%;
}
.with-loading-route-with-token view-route-for-token {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

view-route-for-token .not-visited {
  background-color: #ffcdd2;
}
view-route-for-token .outdrive {
  color: #43a047 !important;
}
view-route-for-token .delay {
  color: #f44336 !important;
}
view-route-for-token .point-link {
  color: rgb(33, 150, 243);
}
view-route-for-token legend {
  margin-top: 16px;
}
view-route-for-token .is-visited {
  background-color: rgba(139, 195, 74, 0.3);
}
view-route-for-token .is-not-visited {
  background-color: rgba(211, 47, 47, 0.3);
}

.without-legend legend {
  display: none;
}

user-list-subscribed-to-notifications {
  display: block;
  width: 100%;
  flex-basis: 100%;
  box-sizing: border-box;
  padding: 6px 10px 6px 20px;
}
user-list-subscribed-to-notifications .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 0;
}
user-list-subscribed-to-notifications .item md-select {
  width: 200px;
  margin: 0;
}
user-list-subscribed-to-notifications .item md-checkbox {
  margin: 0;
}
user-list-subscribed-to-notifications .item .is-disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

users-subscribed-to-notifications .is-label {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  user-select: none;
}
users-subscribed-to-notifications .is-label:hover,
users-subscribed-to-notifications .is-label:hover md-icon {
  color: rgb(33, 150, 243);
}
users-subscribed-to-notifications .is-label md-icon {
  margin: 0 6px 0 0;
  font-size: 36px;
  width: 36px;
  height: 36px;
}
users-subscribed-to-notifications .notification-settings__row {
  gap: 60px;
}
users-subscribed-to-notifications md-select {
  width: 100%;
}
users-subscribed-to-notifications .reload-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
users-subscribed-to-notifications .reload-btn md-icon {
  font-size: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

notification-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
notification-settings .notification-settings--with-all-settings-preloader {
  position: relative;
}
notification-settings .notification-settings__all-settings-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-505, -50%);
}
notification-settings .notification-settings__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
notification-settings .notification-settings__row--disabled {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}
notification-settings .notification-settings__row--hidden {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
notification-settings .notification-settings__row--telegram {
  justify-content: end;
}
notification-settings md-progress-circular {
  width: 30px !important;
  height: 30px !important;
}
notification-settings md-progress-circular svg {
  width: 30px !important;
  height: 30px !important;
  transform-origin: 15px 15px 15px !important;
}

telegram-user-settings md-select {
  width: 100%;
  margin: 0;
}
telegram-user-settings .is-disabled {
  opacity: 0.3;
  user-select: none;
  pointer-events: none;
}

.global-notification-settings__option--telegram {
  gap: 16px;
}
@media (max-width: 1120px) {
  .global-notification-settings__option--telegram {
    flex-direction: column;
    height: auto !important;
    align-items: end !important;
    padding-bottom: 4px;
  }
}

global-notification-settings .md-subheader {
  background-color: transparent;
}
global-notification-settings telegram-user-settings {
  width: 100%;
}

.global-notification-settings__body {
  position: relative;
  display: block;
}

.global-notification-settings__section {
  display: block;
}

.global-notification-settings__section-header {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.global-notification-settings__section-content {
  box-sizing: border-box;
  padding-left: 20px;
}

.global-notification-settings__load-all-settings-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.global-notification-settings__failed-loading-of-all-settings-view {
  display: flex;
  justify-content: center;
}

.global-notification-settings__loading-all-settings-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-notification-settings__options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.global-notification-settings__options--hidden {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.global-notification-settings__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.global-notification-settings__option md-progress-circular {
  width: 30px !important;
  height: 30px !important;
}
.global-notification-settings__option md-progress-circular svg {
  width: 30px !important;
  height: 30px !important;
  transform-origin: 15px 15px 15px !important;
}

.global-notification-settings__option-right-part {
  display: flex;
  align-items: center;
  gap: 20px;
}

#toast-container > div {
  opacity: 1;
}